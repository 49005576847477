import { graphql } from 'gatsby';
import React from 'react';
import Header from '../components/header';
import Layout from '../components/layout';
import SEO from '../components/seo';


const Temoignage = ({ data }) => {
  const pascale = <><b>Pascale</b></>
  const aurelie = <><b>Aurélie</b></>
  const bea = <><b>Béa</b></>
  const alice = <><b>Alice</b></>
  const gerard = <><b>Gérard</b></>
  const didier = <><b>Didier</b></>
  const audrey = <><b>Audrey</b></>
  const nathalie = <><b>Nathalie</b></>
  const sophie = <><b>Sophie</b></>

  return (
    <Layout>
      <SEO title="Témoignages" />
      <Header metadata={data.site.siteMetadata} />
      {pascale}<br/>
      "En séance d’équicoaching chez "Du cheval à soi". Un moment de pure collaboration avec le cheval pour
      pouvoir se recentrer sur son parcours et aller de l’avant. Un moment rien qu’à moi et pour moi afin
      d’accompagner encore mieux les personnes qui me rejoignent dans ma profession"<br/><br/>

      {aurelie}<br/>
      "Je suis dans le développement personnel et une curieuse de la vie c'est donc pour ces raisons que j'ai
      tenté l'expérience de l'équicoaching. Cette séance dirigée par Nancy assisté de son cheval Majeed, un
      magnifique pur-sang arabe, m'a fortement secouée. Contre toute attente, le duo a mis en lumière
      certains aspects de ma vie dont je n'étais pas consciente...<br/>
      Merci à Nancy pour sa bienveillance et son professionnalisme qui m'ont permis de découvrir les blocages
      qui freinaient mon évolution...<br/>
      Je recommande à ceux et celles qui veulent faire un virage de 180° sur eux même.<br/>
      Découverte d'une thérapie très constructive. Merci🙏"<br/><br/>

      {bea}<br/>
      "Merci Playboy... Et merci Nancy pour vos accompagnements...<br/>
      L'équicoaching, une technique puissante qui enlève des barrières, permet de se reconnecter à ses
      émotions et d'être qui vous êtes.<br/>
      Le cheval est connecté à vos émotions et ressentis et il est cash...<br/>
      Je vous recommande "Du cheval à soi""<br/><br/>
      
      {alice}<br/>
      "J'ai vécu une expérience forte en émotions, Nancy a une sensibilité et un ressenti aiguisé et elle
      maîtrise bien son métier d'équicoach. Elle m'a emmenée avec l'aide de Playboy dans des endroits
      intérieurs et dans mon cœur... J'ai découvert mon identité... Je suis une femme aimante et douce... J'ai
      redécouvert ma féminité... Un grand merci à vous deux. Je recommande vraiment à chaque personne en
      quête d'intériorité, de changement positif et constructif de faire des séances avec Nancy et
      Playboy..."<br/><br/>

      {gerard}<br/>
      "Les séances d'équicoaching sont des moments où le cheval prend soin de l'humain et lui montre le
      chemin à suivre pour retrouver son vrai moi.... J'adhère à 1000% ....merci Nancy de me permettre d'en
      vivre personnellement et merci à Majeed mon maître équin..."<br/><br/>

      {sophie}<br/>
      "J’ai eu la chance de profiter d’une séance de coaching avec Nancy et son cheval. Après une discussion
      sur ce que je venais chercher, Nancy m’a proposé un exercice et a vérifié que j’étais d’accord de me
      lancer. Immédiatement, j’ai été plongée au cœur de mes ressentis les plus essentiels, ce qui m’a fait
      gagner beaucoup de temps. Nancy s’est montrée respectueuse, professionnelle, bienveillante. Son
      cheval était lui parfois bien plus direct 🤣.
      Étant coach certifiée, j’ai aussi observé Nancy dans sa pratique. Elle a une posture de coach adéquate,
      connaît ses limites, respecte le rythme de son interlocuteur, ne « plaque » pas d’interprétation sur ce qui
      se passe..."<br/><br/>

      {didier}<br/>
      "Merci Nancy pour cet équicoaching, tu as quand même réussi à me faire sortir une ou deux larmes,
      travail très intéressant, et de manière professionnel."<br/><br/>

      {audrey}<br/>
      "Séance puissante, forte en émotions. Nancy a un don et sait s’y prendre. On dirait qu'elle a fait ça toute
      sa vie. Merci à toi et à Playboy pour cette séance et ce riche partage..."<br/><br/>

      {nathalie}<br/>
      "Au début de la séance, j'étais un peu anxieuse (de nature) quant à la manière dont celle-ci allait se
      dérouler mais très vite, je me suis sentie rassurée grâce au contact qui a démarré tout en douceur avec
      Playboy. Ce sentiment de quiétude s'est poursuivi jusqu'à la fin car, tout en parlant avec Nancy, j'étais à
      la fois ravie et ébahie de voir à quel point Playboy participait à la leçon en gardant ce calme et cette
      aisance que peuvent avoir ces animaux plus que merveilleux. C'est une expérience qui m'a apporté une
      sensation de soulagement par rapport à mes problèmes et que je n'hésiterai pas à refaire et surtout, je la
      recommande à toute personne qui a besoin d'une petite éclaircie, que ce soit dans sa vie familiale,
      professionnelle ou autre et qui, bien évidemment aime beaucoup les chevaux. Merci à toi Nancy, c'était
      super......."<br/><br/>
    </Layout>
  );
};

export default Temoignage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        name
        title
        description
        about
        author
        github
        linkedin
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
